<template>
  <v-form>
    <v-layout>
      <v-app-bar app height="124" class="align-start elevation-1">
        <v-col cols="12" class="py-0">
          <v-row dense class="align-center flex-nowrap">
            <v-col cols="auto" class="d-flex d-lg-none pa-0">
              <!--Platzhalter für mobile Menu-->
              <div style="width: 50px"></div>
            </v-col>
            <v-col cols="auto">
              <v-toolbar-title>Artikel erstellen</v-toolbar-title>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="auto">
              <v-btn outlined color="red lighten-1" @click="$router.go(-1)">
                <v-icon>mdi-cancel</v-icon>
                <span class="ml-1 d-none d-lg-flex">Abbrechen</span>
              </v-btn>
            </v-col>
            <v-col cols="auto">
              <v-btn color="green lighten-1 grey--text text--lighten-4" @click="artikelSpeichern()" :disabled="validInsert || checkNames">
                <v-icon>mdi-check</v-icon>
                <span class="ml-1 d-none d-lg-flex">Speichern</span>
              </v-btn>
            </v-col>
          </v-row>
          <v-row dense class="align-center">
            <v-spacer></v-spacer>
            <v-col cols="auto">
              <v-select
                hide-details
                dense
                outlined
                required
                placeholder="Bearbeiter(*)"
                v-model="newDatasetEvent.Bearbeiter_ID"
                :items="Bearbeiter"
                item-text="Vorname"
                item-value="Bearbeiter_ID"
              >
              </v-select>
            </v-col>
          </v-row>
        </v-col>
      </v-app-bar>

      <v-container fluid class="px-0 pt-0">
        <v-col cols="11" xl="8" lg="9" md="11" class="mx-auto px-0 pt-0">
          <v-row dense class="align-center justify-center pb-5">
            <v-card tile outlined class="flex-grow-1">
              <v-col cols="12">
                <v-card-title class="px-2">
                  <v-row dense align="center">
                    <v-col cols="12">
                      <v-toolbar-title>
                        Hauptdaten
                      </v-toolbar-title>
                    </v-col>
                  </v-row>
                </v-card-title>
                <v-divider inset></v-divider>
                <v-card-text class="px-2 subtitle-2">
                  <v-row dense align="center">
                    <v-col cols="12" xl="5" lg="6" md="7" sm="8">
                      <span class="font-weight-bold">Art.-Nr.(*)</span>
                      <v-text-field v-model="newDataset.Artikelnummer" hide-details dense outlined required clearable placeholder="Artikelnummer">
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row dense align="start">
                    <v-col cols="12" sm="7" md="4" lg="4" xl="4">
                      <span class="font-weight-bold">Kategorie(*)</span>
                      <v-select
                        hide-details
                        dense
                        outlined
                        required
                        open-on-clear
                        v-model="newDataset.Abteilung_ID"
                        :items="Abteilungen"
                        item-text="Abteilung"
                        item-value="Abteilung_ID"
                        placeholder="Abteilung"
                        @input="clearGroupAndSubgroup()"
                        ><template v-slot:no-data>
                          <span class="text-center d-block">keine Abteilung gefunden</span>
                        </template>
                      </v-select>
                      <div class="pt-1" v-if="newDataset.Abteilung_ID">
                        <v-select
                          hide-details
                          dense
                          outlined
                          required
                          open-on-clear
                          v-model="newDataset.Artikelgruppe_ID"
                          :items="filteredArtikelgruppen"
                          item-text="Artikelgruppe"
                          item-value="Artikelgruppe_ID"
                          placeholder="Gruppe"
                          clearable
                          :disabled="!newDataset.Abteilung_ID && filteredArtikelgruppen.length === 0"
                          @click:clear="clearSubgroup()"
                          @input="clearSubgroup()"
                          ><template v-slot:no-data>
                            <span class="text-center d-block">nicht vorhanden</span>
                          </template>
                        </v-select>
                      </div>
                      <div class="pt-1" v-if="newDataset.Artikelgruppe_ID">
                        <v-select
                          hide-details
                          dense
                          outlined
                          required
                          open-on-clear
                          clearable
                          v-model="newDataset.Artikeluntergruppe_ID"
                          :items="filteredArtikeluntergruppen"
                          item-text="Artikeluntergruppe"
                          item-value="Artikeluntergruppe_ID"
                          placeholder="Untergruppe"
                          :disabled="!newDataset.Artikelgruppe_ID && filteredArtikeluntergruppen.length === 0"
                          ><template v-slot:no-data>
                            <span class="text-center d-block">nicht vorhanden</span>
                          </template>
                        </v-select>
                      </div>
                    </v-col>
                    <v-col cols="6" sm="4" md="3" lg="2" xl="2">
                      <span class="font-weight-bold">Größe(*)</span>
                      <v-text-field
                        v-model="newDataset.Größe"
                        hide-details
                        dense
                        outlined
                        clearable
                        required
                        placeholder="Größe"
                        @click:clear="$nextTick(() => clearArticlename())"
                        @input="clearArticlename()"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="8" md="5" lg="6" xl="6">
                      <span class="font-weight-bold">Artikelname(*)</span>
                      <v-combobox
                        v-model="newDataset.Artikelname"
                        :items="filteredArtikelnamen"
                        :error="checkNames"
                        :disabled="!newDataset.Abteilung_ID || !newDataset.Größe"
                        item-value="Artikelname_ID"
                        item-text="Artikelname"
                        dense
                        outlined
                        placeholder="Artikelname"
                        hide-details
                        clearable
                        open-on-clear
                        ><template v-slot:no-data>
                          <span class="text-center d-block">bitte neuen Artikelnamen eingeben</span>
                        </template>
                      </v-combobox>
                    </v-col>
                  </v-row>
                  <v-divider inset class="my-3"></v-divider>
                  <v-row dense align="center">
                    <v-col cols="12" xl="4" lg="4" md="4" sm="4">
                      <span class="font-weight-bold">Bestand</span>
                      <v-text-field
                        hide-details
                        dense
                        outlined
                        required
                        clearable
                        v-model="newDataset.Bestand"
                        placeholder="1"
                        @click:clear="$nextTick(() => clearBestand())"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" xl="4" lg="4" md="4" sm="4">
                      <span class="font-weight-bold">Einheit</span>
                      <v-select
                        hide-details
                        dense
                        outlined
                        v-model="newDataset.Einheit_ID"
                        :items="Einheiten"
                        item-text="Einheit"
                        item-value="Einheit_ID"
                        placeholder="Einheit"
                      >
                      </v-select>
                    </v-col>
                    <v-col cols="12" xl="4" lg="4" md="4" sm="4">
                      <span class="font-weight-bold">bestandsgeführt</span>
                      <v-checkbox
                        dense
                        hide-details
                        class="pb-1 pt-1 mt-0"
                        v-model="newDataset.ist_bestandsgeführt"
                        :true-value="1"
                        :false-value="0"
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                  <v-row dense align="center">
                    <v-col v-if="newDataset.ist_bestandsgeführt === 1" cols="12" xl="4" lg="4" md="4" sm="4">
                      <span class="font-weight-bold">Mindestbestand</span>
                      <v-text-field
                        hide-details
                        dense
                        outlined
                        required
                        clearable
                        v-model="newDataset.Mindestbestand"
                        placeholder="1"
                        @click:clear="$nextTick(() => clearMindestbestand())"
                      >
                      </v-text-field>
                    </v-col>
                    <v-spacer></v-spacer>
                  </v-row>
                </v-card-text>
              </v-col>
            </v-card>
          </v-row>
          <v-row dense class="align-center justify-center pb-5" v-if="newDataset.Status_ID && newDataset.Status_ID !== 1">
            <v-card tile outlined class="flex-grow-1">
              <v-col cols="12">
                <v-card-title class="px-2">
                  <v-row dense>
                    <v-col cols="12">
                      <v-toolbar-title>
                        Eigenschaften / Details
                      </v-toolbar-title>
                    </v-col>
                  </v-row>
                </v-card-title>
                <v-divider inset></v-divider>
                <v-card-text class="px-2 subtitle-2">
                  <v-row dense align="start">
                    <v-col cols="12" xl="4" lg="4" md="6" sm="6">
                      <span class="font-weight-bold">Farben</span>
                      <v-autocomplete
                        hide-details
                        dense
                        outlined
                        clearable
                        open-on-clear
                        v-model="newDataset.Hauptfarbe_ID"
                        :items="Hauptfarben"
                        item-text="Farbe"
                        item-value="Farb_ID"
                        placeholder="Hauptfarbe"
                      >
                      </v-autocomplete>
                      <div class="pt-1" v-if="newDataset.Hauptfarbe_ID">
                        <v-autocomplete
                          hide-details
                          dense
                          outlined
                          clearable
                          open-on-clear
                          v-model="newDataset.Nebenfarbe_ID"
                          :items="Nebenfarben"
                          item-text="Farbe"
                          item-value="Farb_ID"
                          placeholder="Nebenfarbe"
                        >
                        </v-autocomplete>
                      </div>
                    </v-col>
                    <v-col cols="12" xl="4" lg="4" md="6" sm="6">
                      <span class="font-weight-bold">Stoffe</span>
                      <v-autocomplete
                        hide-details
                        dense
                        outlined
                        clearable
                        open-on-clear
                        v-model="newDataset.Hauptstoff_ID"
                        :items="Hauptstoffe"
                        item-text="Stoff"
                        item-value="Stoff_ID"
                        placeholder="Hauptstoff"
                      >
                      </v-autocomplete>
                      <div class="pt-1" v-if="newDataset.Hauptstoff_ID">
                        <v-autocomplete
                          hide-details
                          dense
                          outlined
                          clearable
                          open-on-clear
                          v-model="newDataset.Nebenstoff_ID"
                          :items="Nebenstoffe"
                          item-text="Stoff"
                          item-value="Stoff_ID"
                          placeholder="Nebenstoff"
                        >
                        </v-autocomplete>
                      </div>
                    </v-col>
                    <v-col cols="12" xl="4" lg="4" md="6" sm="6">
                      <span class="font-weight-bold">Schnitt</span>
                      <v-autocomplete
                        hide-details
                        dense
                        outlined
                        clearable
                        open-on-clear
                        v-model="newDataset.Schnitt_ID"
                        :items="Schnitte"
                        item-text="Schnitt"
                        item-value="Schnitt_ID"
                        placeholder="Schnitt"
                      >
                      </v-autocomplete>
                    </v-col>
                  </v-row>
                  <v-divider inset class="my-3"></v-divider>
                  <v-row dense align="center">
                    <v-col cols="12" xl="6" lg="8" md="10" sm="10">
                      <span class="font-weight-bold">Besonderheiten</span>
                      <v-textarea
                        hide-details
                        dense
                        outlined
                        clearable
                        v-model="newDataset.Besonderheiten"
                        placeholder="Besonderheiten / Beschreibung"
                      >
                      </v-textarea>
                    </v-col>
                  </v-row>
                  <v-row dense align="center" class="d-none">
                    <v-col cols="12" xl="4" lg="5" md="6" sm="6">
                      <span class="font-weight-bold">Bild</span>
                      <v-file-input v-model="newDataset.Bild" show-size truncate-length="20" clearable placeholder="Bild hochladen"></v-file-input>
                    </v-col>
                  </v-row>
                  <v-divider inset class="my-3"></v-divider>
                  <v-row dense align="center">
                    <v-col cols="12" xl="5" lg="5" md="6" sm="6">
                      <span class="font-weight-bold">Hersteller</span>
                      <v-autocomplete
                        hide-details
                        dense
                        outlined
                        clearable
                        open-on-clear
                        v-model="newDataset.Hersteller_ID"
                        :items="Hersteller"
                        item-text="Name"
                        item-value="Hersteller_ID"
                        placeholder="Hersteller"
                        @change="newDataset.Bestellstatus_ID = 2"
                        @click:clear="newDataset.Bestellstatus_ID = 2"
                      >
                      </v-autocomplete>
                    </v-col>
                    <v-col v-if="newDataset.Hersteller_ID" cols="12" xl="5" lg="5" md="6" sm="6" class="mt-auto"
                      ><span class="font-weight-bold">nicht mehr bestellbar</span>
                      <v-checkbox
                        dense
                        hide-details
                        class="pb-1 pt-1 mt-0"
                        v-model="newDataset.Bestellstatus_ID"
                        :true-value="1"
                        :false-value="2"
                      ></v-checkbox
                    ></v-col>
                  </v-row>
                </v-card-text>
              </v-col>
            </v-card>
          </v-row>
          <v-row dense class="align-center justify-center" v-if="newDataset.Status_ID && newDataset.Status_ID !== 1">
            <v-card tile outlined class="flex-grow-1">
              <v-col cols="12">
                <v-card-title class="px-2">
                  <v-row dense>
                    <v-col cols="12">
                      <v-toolbar-title>
                        Preise
                      </v-toolbar-title>
                    </v-col>
                  </v-row>
                </v-card-title>
                <v-divider inset></v-divider>
                <v-card-text class="px-2 subtitle-2">
                  <v-row dense align="center">
                    <v-col cols="12" xl="4" lg="5" md="6" sm="6">
                      <span class="font-weight-bold">MwSt.-Satz</span>
                      <v-select
                        hide-details
                        dense
                        outlined
                        v-model="Berechnungen.MwSt"
                        :items="MwSt"
                        item-text="MwSt"
                        item-value="MwSt_ID"
                        return-object
                        @change="VK_brutto_Berechnung()"
                        placeholder="MwSt.-Satz"
                      >
                      </v-select>
                    </v-col>
                  </v-row>
                  <v-row dense align="center">
                    <v-col cols="6" xl="3" lg="3" md="3" sm="4">
                      <span class="font-weight-bold">EK netto <span v-if="checkCurrentUser">(normal)</span></span>
                      <v-text-field
                        hide-details
                        dense
                        outlined
                        clearable
                        v-model="Berechnungen.EK_netto"
                        @change="VK_brutto_Berechnung()"
                        @click:clear="$nextTick(() => clearEKnetto())"
                        placeholder="0,00"
                        suffix="€"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col v-if="checkCurrentUser" cols="6" xl="3" lg="3" md="3" sm="4">
                      <span class="font-weight-bold">EK netto</span>
                      <v-text-field
                        hide-details
                        dense
                        outlined
                        clearable
                        v-model="Berechnungen.EK_netto_spezial"
                        @change="formatEKnettoSpezial()"
                        @click:clear="$nextTick(() => clearEKnettospezial())"
                        placeholder="0,00"
                        suffix="€"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-divider inset class="my-3"></v-divider>
                  <v-row dense align="center" class="pt-6">
                    <v-col cols="12" xl="7" lg="8" md="9" sm="10">
                      <v-slider
                        v-model="Berechnungen.Kalkulation"
                        class="align-center"
                        max="5.00"
                        min="0.00"
                        hide-details
                        :disabled="!Berechnungen.EK_netto || !Berechnungen.VK_brutto"
                        thumb-label="always"
                        :thumb-size="42"
                        step="any"
                        @input="VK_brutto_Berechnung()"
                      >
                        <template v-slot:thumb-label="{ value }">
                          {{ (value * 100).toFixed(0) + "%" }}
                        </template>
                      </v-slider>
                      <span class="font-weight-bold">Kalkulation</span>
                    </v-col>
                  </v-row>
                  <v-divider inset class="my-3"></v-divider>
                  <v-row dense align="center">
                    <v-col cols="6" xl="3" lg="3" md="3" sm="4">
                      <span class="font-weight-bold">VK brutto</span>
                      <v-text-field
                        hide-details
                        dense
                        outlined
                        clearable
                        v-model="Berechnungen.VK_brutto"
                        placeholder="0,00"
                        suffix="€"
                        @change="Kalkulation_Berechnung()"
                        @click:clear="$nextTick(() => clearVKbrutto())"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="6" xl="3" lg="3" md="3" sm="4">
                      <span class="font-weight-bold">Leihpreis</span>
                      <v-text-field
                        hide-details
                        dense
                        outlined
                        clearable
                        v-model="Berechnungen.Leihpreis"
                        placeholder="0,00"
                        suffix="€"
                        @change="$nextTick(() => formatLeihpreis())"
                        @click:clear="$nextTick(() => clearLeihpreis())"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-col>
            </v-card>
          </v-row>
        </v-col>
      </v-container>
    </v-layout>

    <LeavePathWrapper ref="leavePath">
      <LeavePathDialog />
    </LeavePathWrapper>
  </v-form>
</template>

<script>
import LeavePathWrapper from "../../../../components/dialogs/LeavePathWrapper.vue";
import LeavePathDialog from "../../../../components/dialogs/LeavePathDialog.vue";

export default {
  components: {
    LeavePathWrapper,
    LeavePathDialog,
  },

  mounted() {
    this.initialize();
  },

  beforeRouteLeave(to, from, next) {
    if (this.saved === true || this.checkDifferences === false) {
      next();
    } else if (this.saved === false) {
      this.$refs.leavePath.open().then((result) => {
        if (result === true) {
          next();
        } else if (result === false) {
          next(false);
        }
      });
    }
  },

  data: () => ({
    Abteilungen: [],
    Artikelgruppen: [],
    Artikeluntergruppen: [],
    Bestellstatus: [],
    Artikelnamen: [],
    Artikelnamenliste: [],
    Hauptfarben: [],
    Nebenfarben: [],
    Hauptstoffe: [],
    Nebenstoffe: [],
    Schnitte: [],
    Hersteller: [],
    Einheiten: [],
    MwSt: [],
    Bearbeiter: [],
    Berechnungen: {
      EK_netto: "",
      EK_netto_spezial: "",
      VK_brutto: "",
      Kalkulation: "",
      EK_brutto: "",
      VK_netto: "",
      Leihpreis: "",
      MwSt: {
        MwSt_ID: 1,
        MwSt: "19%",
        Satz: 1.19,
      },
    },
    defaultDataset: {
      Abteilung_ID: null,
      Artikelgruppe_ID: null,
      Artikeluntergruppe_ID: null,
      Artikelnummer: null,
      Artikelname_ID: null,
      Artikelname: null,
      Größe: null,
      Status_ID: 2,
      Bestellstatus_ID: 2,
      Hauptfarbe_ID: null,
      Nebenfarbe_ID: null,
      Hauptstoff_ID: null,
      Nebenstoff_ID: null,
      Schnitt_ID: null,
      Besonderheiten: null,
      Hersteller_ID: null,
      VK_netto: "",
      VK_brutto: "",
      Leihpreis: "",
      EK_netto: "",
      EK_netto_spezial: "",
      EK_brutto: "",
      MwSt_ID: 1,
      Kalkulation: null,
      ist_bestandsgeführt: 0,
      Bestand: 1,
      Einheit_ID: 1,
      Mindestbestand: null,
      Bild: null,
      Anzahl_verliehen: 0,
    },
    newDataset: {},
    defaultDatasetEvent: {
      Vorgang_ID: null,
      Artikel_ID: null,
      Termin_ID: null,
      Kunden_ID: null,
      VK_brutto: null,
      Leihpreis: null,
      EK_netto: null,
      // Datum wird automatisch erstellt
      Bearbeiter_ID: null,
    },
    newDatasetEvent: {},
    saved: false,
  }),

  computed: {
    checkCurrentUser() {
      const user = localStorage.getItem("user");

      if (user === "Aline" || user === "Admin") {
        return true;
      } else return false;
    },

    checkNames() {
      const findname = this.Artikelnamen.filter((item) => item.Artikelname === this.newDataset.Artikelname && item.Größe === this.newDataset.Größe);
      if (findname.length > 0) {
        return true;
      } else return false;
    },

    filteredArtikelnamen() {
      if (this.newDataset.Abteilung_ID && !this.newDataset.Größe) {
        return this.Artikelnamenliste.filter((item) => item.Abteilung_ID === this.newDataset.Abteilung_ID);
      } else if (!this.newDataset.Abteilung_ID && this.newDataset.Größe) {
        return this.Artikelnamenliste.filter((item) => item.Größe === this.newDataset.Größe);
      } else if (this.newDataset.Abteilung_ID && this.newDataset.Größe) {
        return this.Artikelnamenliste.filter((item) => item.Abteilung_ID === this.newDataset.Abteilung_ID && item.Größe === this.newDataset.Größe);
      } else return [];
    },

    filteredArtikelgruppen() {
      if (this.newDataset.Abteilung_ID) {
        return this.Artikelgruppen.filter((item) => item.Abteilung_ID === this.newDataset.Abteilung_ID);
      } else return [];
    },

    filteredArtikeluntergruppen() {
      if (this.newDataset.Artikelgruppe_ID) {
        return this.Artikeluntergruppen.filter((item) => item.Artikelgruppe_ID === this.newDataset.Artikelgruppe_ID);
      } else return [];
    },

    checkDifferences() {
      if (
        this.newDataset.Abteilung_ID === this.defaultDataset.Abteilung_ID &&
        this.newDataset.Artikelgruppe_ID === this.defaultDataset.Artikelgruppe_ID &&
        this.newDataset.Artikeluntergruppe_ID === this.defaultDataset.Artikeluntergruppe_ID &&
        this.newDataset.Artikelnummer === this.defaultDataset.Artikelnummer &&
        this.newDataset.Artikelname_ID === this.defaultDataset.Artikelname_ID &&
        this.newDataset.Größe === this.defaultDataset.Größe &&
        this.newDataset.Status_ID === this.defaultDataset.Status_ID &&
        this.newDataset.Bestellstatus_ID === this.defaultDataset.Bestellstatus_ID &&
        this.newDataset.Liefertermin_von === this.defaultDataset.Liefertermin_von &&
        this.newDataset.Liefertermin_bis === this.defaultDataset.Liefertermin_bis &&
        this.newDataset.Hauptfarbe_ID === this.defaultDataset.Hauptfarbe_ID &&
        this.newDataset.Nebenfarbe_ID === this.defaultDataset.Nebenfarbe_ID &&
        this.newDataset.Hauptstoff_ID === this.defaultDataset.Hauptstoff_ID &&
        this.newDataset.Nebenstoff_ID === this.defaultDataset.Nebenstoff_ID &&
        this.newDataset.Schnitt_ID === this.defaultDataset.Schnitt_ID &&
        this.newDataset.Besonderheiten === this.defaultDataset.Besonderheiten &&
        this.newDataset.Hersteller_ID === this.defaultDataset.Hersteller_ID &&
        this.Berechnungen.EK_netto === this.defaultDataset.EK_netto &&
        this.Berechnungen.EK_netto_spezial === this.defaultDataset.EK_netto_spezial &&
        this.Berechnungen.EK_brutto === this.defaultDataset.EK_brutto &&
        this.Berechnungen.VK_netto === this.defaultDataset.VK_netto &&
        this.Berechnungen.VK_brutto === this.defaultDataset.VK_brutto &&
        this.Berechnungen.Leihpreis === this.defaultDataset.Leihpreis &&
        this.Berechnungen.MwSt.MwSt_ID === this.defaultDataset.MwSt_ID &&
        this.newDataset.Bestand === this.defaultDataset.Bestand &&
        this.newDataset.Einheit_ID === this.defaultDataset.Einheit_ID &&
        this.newDataset.ist_bestandsgeführt === this.defaultDataset.ist_bestandsgeführt &&
        this.newDataset.Mindestbestand === this.defaultDataset.Mindestbestand
      ) {
        return false;
      } else return true;
    },

    validInsert() {
      if (
        !this.newDatasetEvent.Bearbeiter_ID ||
        !this.newDataset.Abteilung_ID ||
        !this.newDataset.Artikelnummer ||
        !this.newDataset.Größe ||
        !this.newDataset.Artikelname
      ) {
        return true;
      } else return false;
    },
  },

  methods: {
    async initialize() {
      const response = await fetch(`/api/database/${this.$route.meta.request}/dataset/insert`);
      const json = await response.json();

      for (let [key, value] of Object.entries(json)) {
        this[key] = value;
      }

      this.newDataset = Object.assign({}, this.defaultDataset);
      this.newDatasetEvent = Object.assign({}, this.defaultDatasetEvent);
    },

    clearGroupAndSubgroup() {
      this.newDataset.Artikelgruppe_ID = null;
      this.newDataset.Artikeluntergruppe_ID = null;
      this.clearArticlename();
    },

    clearSubgroup() {
      this.newDataset.Artikeluntergruppe_ID = null;
    },

    clearArticlename() {
      this.newDataset.Artikelname = null;
    },

    VK_brutto_Berechnung() {
      if (this.Berechnungen.EK_netto) {
        const eknetto = this.Berechnungen.EK_netto;
        const kalk = this.Berechnungen.Kalkulation;
        const mwst = this.Berechnungen.MwSt.Satz;

        const eknetto_string = String(parseFloat(eknetto.replace(",", ".")).toFixed(2)).replace(".", ",");

        const ekbrutto = parseFloat(eknetto.replace(",", ".") * mwst);
        const ekbrutto_string = String(ekbrutto.toFixed(2)).replace(".", ",");

        if (kalk == 0) {
          var vknetto = parseFloat(eknetto.replace(",", "."));
        } else if (kalk > 0) {
          var vknetto = parseFloat(eknetto.replace(",", ".") * kalk);
        }
        const vknetto_string = String(vknetto.toFixed(2)).replace(".", ",");

        const vkbrutto = (vknetto * mwst).toFixed(2);
        const vkbrutto_string = String(vkbrutto.replace(".", ","));

        const leihpreis = (vkbrutto / 3).toFixed(2);
        const leihpreis_string = String(leihpreis.replace(".", ","));

        this.Berechnungen.EK_netto = eknetto_string;
        this.Berechnungen.EK_brutto = ekbrutto_string;
        this.Berechnungen.VK_brutto = vkbrutto_string;
        this.Berechnungen.VK_netto = vknetto_string;
        this.Berechnungen.Leihpreis = leihpreis_string;
      }
    },

    Kalkulation_Berechnung() {
      if (this.Berechnungen.EK_netto && this.Berechnungen.VK_brutto) {
        const vkbrutto = this.Berechnungen.VK_brutto;
        const mwst = this.Berechnungen.MwSt.Satz;
        const eknetto = this.Berechnungen.EK_netto;

        const vknetto = parseFloat(vkbrutto.replace(",", ".")) / mwst;
        const vknetto_string = String(vknetto.toFixed(2).replace(".", ","));

        const vkbrutto_string = String(parseFloat(vkbrutto.replace(",", ".")).toFixed(2)).replace(".", ",");

        const kalk = parseFloat(vkbrutto.replace(",", ".")) / (mwst * eknetto.replace(",", "."));

        const leihpreis = parseFloat(vkbrutto.replace(",", ".")) / 3;
        const leihpreis_string = String(leihpreis.toFixed(2)).replace(".", ",");

        this.Berechnungen.VK_brutto = vkbrutto_string;
        this.Berechnungen.Kalkulation = kalk;
        this.Berechnungen.VK_netto = vknetto_string;
        this.Berechnungen.Leihpreis = leihpreis_string;
      } else if (!this.Berechnungen.EK_netto && this.Berechnungen.VK_brutto) {
        const mwst = this.Berechnungen.MwSt.Satz;
        const vkbrutto = this.Berechnungen.VK_brutto;
        const vkbrutto_string = String(parseFloat(vkbrutto.replace(",", ".")).toFixed(2)).replace(".", ",");

        const vknetto = parseFloat(vkbrutto.replace(",", ".")) / mwst;
        const vknetto_string = String(vknetto.toFixed(2).replace(".", ","));

        this.Berechnungen.VK_netto = vknetto_string;
        this.Berechnungen.VK_brutto = vkbrutto_string;
      } else if (this.Berechnungen.EK_netto && !this.Berechnungen.VK_brutto) {
      }
    },

    formatLeihpreis() {
      if (this.Berechnungen.Leihpreis) {
        const leihpreis = this.Berechnungen.Leihpreis;
        const leihpreis_string = String(parseFloat(leihpreis.replace(",", ".")).toFixed(2)).replace(".", ",");
        this.Berechnungen.Leihpreis = leihpreis_string;
      }
    },

    formatEKnettoSpezial() {
      const EK_netto_spezial = this.Berechnungen.EK_netto_spezial;
      const EK_netto_spezial_string = String(parseFloat(EK_netto_spezial.replace(",", ".")).toFixed(2)).replace(".", ",");
      this.Berechnungen.EK_netto_spezial = EK_netto_spezial_string;
    },

    clearLeihpreis() {
      this.newDataset.Leihpreis = null;
    },

    clearBestand() {
      this.newDataset.Bestand = null;
    },

    clearMindestbestand() {
      this.newDataset.Mindestbestand = null;
    },

    clearEKnetto() {
      this.Berechnungen.EK_netto = "";
      this.Berechnungen.EK_brutto = "";
      this.Berechnungen.Kalkulation = null;
      this.Berechnungen.EK_netto_spezial = "";
      this.Ersparnis = "";
    },

    clearEKnettospezial() {
      this.Berechnungen.EK_netto_spezial = "";
      this.Ersparnis = "";
    },

    clearVKbrutto() {
      this.Berechnungen.VK_brutto = "";
      this.Berechnungen.VK_netto = "";
      this.Berechnungen.Kalkulation = null;
    },

    clearLeihpreis() {
      this.Berechnungen.Leihpreis = "";
    },

    async artikelSpeichern() {
      const datasetToInsert = {};
      const articleNameToAdd = {};

      if (this.newDataset.Artikelname && this.newDataset.Artikelname.Artikelname_ID) {
        var newarticlenameid = this.newDataset.Artikelname.Artikelname_ID;
      } else if (this.newDataset.Artikelname && this.filteredArtikelnamen.length > 0) {
        const findname = this.filteredArtikelnamen.filter((item) => item.Artikelname === this.newDataset.Artikelname);
        if (findname.length > 0) {
          newarticlenameid = findname[0].Artikelname_ID;
        } else {
          if (Object.keys(articleNameToAdd).length === 0) {
            Object.assign(articleNameToAdd, {
              Artikelname: this.newDataset.Artikelname,
              Abteilung_ID: this.newDataset.Abteilung_ID,
              Artikelgruppe_ID: this.newDataset.Artikelgruppe_ID,
              Größe: this.newDataset.Größe,
              verwendbar: 1,
            });
          }

          const saveresponse = await fetch(`/api/database/${this.$route.meta.request}/name/insert`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(articleNameToAdd),
          });
          const jsonartikel = await saveresponse.json();

          var newarticlenameid = jsonartikel.lastInsertRowid;
        }
      } else if (this.newDataset.Artikelname === null) {
        var newarticlenameid = null;
      } else {
        if (Object.keys(articleNameToAdd).length === 0) {
          Object.assign(articleNameToAdd, {
            Artikelname: this.newDataset.Artikelname,
            Abteilung_ID: this.newDataset.Abteilung_ID,
            Artikelgruppe_ID: this.newDataset.Artikelgruppe_ID,
            Größe: this.newDataset.Größe,
            verwendbar: 1,
          });
        }

        const saveresponse = await fetch(`/api/database/${this.$route.meta.request}/name/insert`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(articleNameToAdd),
        });
        const jsonartikel = await saveresponse.json();

        var newarticlenameid = jsonartikel.lastInsertRowid;
      }

      if (!this.newDataset.Hersteller_ID) {
        this.newDataset.Bestellstatus_ID = 1;
      }

      if (this.newDataset.ist_bestandsgeführt === 0) {
        this.newDataset.Mindestbestand = null;
      }

      if (!this.Berechnungen.EK_netto || !this.Berechnungen.VK_brutto) {
        this.newDataset.Kalkulation = null;
      } else this.newDataset.Kalkulation = this.Berechnungen.Kalkulation;

      if (Object.keys(datasetToInsert).length === 0) {
        Object.assign(datasetToInsert, {
          Abteilung_ID: this.newDataset.Abteilung_ID,
          Artikelgruppe_ID: this.newDataset.Artikelgruppe_ID,
          Artikeluntergruppe_ID: this.newDataset.Artikeluntergruppe_ID,
          Artikelnummer: this.newDataset.Artikelnummer,
          Artikelname_ID: newarticlenameid,
          Größe: this.newDataset.Größe,
          Status_ID: 2,
          Bestellstatus_ID: this.newDataset.Bestellstatus_ID,
          Hauptfarbe_ID: this.newDataset.Hauptfarbe_ID,
          Nebenfarbe_ID: this.newDataset.Nebenfarbe_ID,
          Hauptstoff_ID: this.newDataset.Hauptstoff_ID,
          Nebenstoff_ID: this.newDataset.Nebenstoff_ID,
          Schnitt_ID: this.newDataset.Schnitt_ID,
          Besonderheiten: this.newDataset.Besonderheiten,
          Hersteller_ID: this.newDataset.Hersteller_ID,
          VK_netto: this.Berechnungen.VK_netto,
          VK_brutto: this.Berechnungen.VK_brutto,
          Leihpreis: this.Berechnungen.Leihpreis,
          EK_netto: this.Berechnungen.EK_netto,
          EK_netto_spezial: this.Berechnungen.EK_netto_spezial,
          EK_brutto: this.Berechnungen.EK_brutto,
          MwSt_ID: this.Berechnungen.MwSt.MwSt_ID,
          Kalkulation: this.newDataset.Kalkulation,
          ist_bestandsgeführt: this.newDataset.ist_bestandsgeführt,
          Bestand: this.newDataset.Bestand,
          Einheit_ID: this.newDataset.Einheit_ID,
          Mindestbestand: this.newDataset.Mindestbestand,
          Bild: null,
          Anzahl_verliehen: 0,
        });
      }

      const saveresponse = await fetch(`/api/database/${this.$route.meta.request}/dataset/insert`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(datasetToInsert),
      });
      const jsonartikel = await saveresponse.json();
      const newdatasetid = jsonartikel.lastInsertRowid;

      if (newarticlenameid !== null) {
        await fetch(`/api/database/articles/dataset/name/unavailable`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(datasetToInsert),
        });
      }

      this.artikelvorgangErstellung(newdatasetid);
    },

    async artikelvorgangErstellung(datasetid) {
      const datasetEventToInsert = {};

      if (Object.keys(datasetEventToInsert).length === 0) {
        Object.assign(datasetEventToInsert, {
          Vorgang_ID: 1,
          Artikel_ID: datasetid,
          Termin_ID: null,
          Kunden_ID: null,
          Bestellung_ID: null,
          // Datum wird automatisch erstellt
          Bearbeiter_ID: this.newDatasetEvent.Bearbeiter_ID,
        });
      }

      const saveresponse = await fetch(`/api/database/${this.$route.meta.request}/dataset/event/insert`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(datasetEventToInsert),
      });

      if (this.newDataset.Status_ID === 3) {
        this.artikelvorgangBestellung(datasetid);
      }

      this.$root.actionSnackbar.show({
        dataset: "Artikel",
        action: "erstellt",
      });
      this.saved = true;
      this.$router.replace({
        path: `/wws/database/${this.$route.meta.request}/dataset/show/${datasetid}`,
      });
    },

    async artikelvorgangBestellung(datasetid) {
      const datasetEventToInsert = {};

      if (Object.keys(datasetEventToInsert).length === 0) {
        Object.assign(datasetEventToInsert, {
          Vorgang_ID: 6,
          Artikel_ID: datasetid,
          Termin_ID: null,
          Kunden_ID: null,
          VK_brutto: null,
          Leihpreis: null,
          EK_netto: this.Berechnungen.EK_netto,
          // Datum wird automatisch erstellt
          Bearbeiter_ID: this.newDatasetEvent.Bearbeiter_ID,
        });
      }

      const saveresponse = await fetch(`/api/database/${this.$route.meta.request}/dataset/event/insert`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(datasetEventToInsert),
      });
    },
  },
};
</script>
